.cardsContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: center;
}
.cardsContainer > div {
    background-color: white;
    height: 150px;
    width: 400px;
    border-radius: 7px;
    
    box-shadow: 1px 1px 14px lightgray;

}
.cardsContainer > div > h1{
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 30px;
}
.cardsContainer > div > button{
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 10px;
    margin-left: 170px;
    margin-right: 150px;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: cornflowerblue;
    color: white;
    cursor: pointer;
}